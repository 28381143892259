<!-- 购买订单弹窗 -->
<script setup>
import { defineProps, defineEmits, inject, watchEffect, ref } from 'vue';
import { getTradeTypeCoin } from '@/utils/tools';
import { BVG_TOKEN_CONT, USDT_TOKEN_CONT, BVT_TOKEN_CONT } from '@/contracts/polygonAddress.js';
import CoinToken from '@/contracts/CoinToken.js';
import { getAccountBalan } from '@/contracts/helper.js';
import useAccountStore from '@/store/accountStore';

const props = defineProps({
  isApprove: {
    type: Boolean,
    default: false
  }
});

const accStore = useAccountStore();
const $emits = defineEmits(['confirm', 'cancel']);

const priceObj = inject('priceObj'); // 价格对象：price、doller
const coin = getTradeTypeCoin(priceObj.tradeType);

const showBalan = ref(0); // 要展示的余额
watchEffect(async () => {
  if (priceObj.tradeType == 1) {
    // 请求bvt余额
    const bvgToken = new CoinToken(BVT_TOKEN_CONT);
    showBalan.value = await bvgToken.getBalance();
  } else if (priceObj.tradeType == 2) {
    // 请求bvg余额
    const bvgToken = new CoinToken(BVG_TOKEN_CONT);
    showBalan.value = await bvgToken.getBalance();
  } else if (priceObj.tradeType == 3) {
    // 请求bnb余额
    showBalan.value = await getAccountBalan();
  } else if (priceObj.tradeType == 4) {
    // 请求usdt余额
    const bvgToken = new CoinToken(USDT_TOKEN_CONT);
    showBalan.value = await bvgToken.getBalance();
  } else if (priceObj.tradeType == 5) {
    // 请求matic余额
    showBalan.value = await getAccountBalan();
  }
  accStore.setBanlance(showBalan.value);
});
</script>

<template>
  <div class="order-pop-wrap">
    <header>{{ $t('detail.45') }}</header>
    <div class="tips">{{ $t('detail.46') }}</div>
    <!-- 价格 -->
    <div class="price-container">
      <div :class="['coins', `icons-${coin}`]"></div>
      <div class="price">
        {{ Number(priceObj.price).toFixed(3) }} {{ String(coin).toUpperCase() }}
      </div>
      <!-- <div class="doller">≈ ${{ priceObj.doller }}</div> -->
    </div>

    <!-- 剩余的bnb -->
    <div class="ava-bnb">
      <span>{{ $t('detail.47') }}</span
      ><span style="margin-left: 0.27rem"
        >{{ Number(showBalan).toFixed(3) }} {{ String(coin).toUpperCase() }}</span
      >
    </div>

    <div class="more-crypto" v-show="Number(showBalan) < Number(priceObj.price)">
      <i class="iconfont icon-tanhao icons"></i>
      <span style="margin-left: 0.1rem">{{ $t('detail.48') }}</span>
    </div>

    <div class="btns">
      <button @click="$emits('confirm')">
        {{ props.isApprove ? $t('dialog.1') : $t('dialog.8') }}
      </button>
      <button @click="$emits('cancel')">{{ $t('dialog.6') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.order-pop-wrap {
  width: 6.48rem;
  // height: 4.17rem;
  background-image: linear-gradient(360deg, #402866 0%, #000 100%);
  padding: 0.24rem 0.4rem 0.5rem;
  border: solid 0.01rem rgba(255, 255, 255, 0.3);
  border-radius: 0.1rem;
  color: #fff;
}

header {
  font-size: 0.4rem;
  margin-bottom: 0.08rem;
}

.tips {
  font-size: 0.24rem;
}

.price-container {
  margin: 0.3rem auto 0.06rem;
  @include flexPos(center);
  > div {
    margin-left: 0.05rem;
  }

  .icon-coin {
    font-size: 0.38rem;
  }

  .price {
    font-size: 0.4rem;
  }

  .doller {
    font-size: 0.2rem;
  }
}

.ava-bnb {
  font-size: 0.18rem;
  @include flexPos(center);
}

.more-crypto {
  @include flexPos(center);
  font-size: 0.16rem;
  margin: 0.26rem 0 0;
  color: rgba(255, 255, 255, 0.5);
  .icons {
    font-size: 0.2rem;
    color: yellow;
  }
}

.btns {
  @include flexPos(space-around);
  margin-top: 0.2rem;
  button {
    width: 1.57rem;
    height: 0.49rem;
    border-radius: 0.25rem;
    font-size: 0.22rem;
    font-weight: 500;
    background-color: transparent;
    border: solid 1px #fff;
    cursor: pointer;
  }
}
</style>
