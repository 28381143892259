<!-- 交易市场详情页 -->
<script setup>
import { provider, ethers, signer } from "@/utils/ethers";
import { computed, reactive, ref, provide, watch, watchEffect } from "vue";
import { useAppStore } from "@store/appStore";
import useAccountStore from "@/store/accountStore";
import MarketDetail from "@cps/MarketDetailComp";
import useMarketStore from "@store/cattleMarketStore";
import useCattleMintStore from "@contractStore/cattleMintStore";
import {
  hasParents,
  // cattleName,
  cattleDesc,
  cattleImg,
  cattleInfo,
  fatherInfo,
  motherInfo,
  cattleDeadTime,
  setBlindBoxParents,
  setCattleDetailInfo
} from "./useMarketDetail.js";
import {
  USDT_TOKEN_CONT,
  BVT_TOKEN_CONT,
  BVG_TOKEN_CONT,
  NFT_MINT_CONT,
  CATTLE_CONT,
  STAR_CONT,
  BLIND_BOX_CONT as POLYGON_BLIND_BOX_CONT
} from "@/contracts/polygonAddress";
import { useRoute, useRouter } from "vue-router";
import useMintStore from "@contractStore/cattleMintStore";
import useCattleStore from "@contractStore/cattleStore";
import usePlanetStore from "@contractStore/planetStore";
import useBlindBoxStore from "@contractStore/blindBoxStore";
import OrderPop from "@cps/MarketDetailComp/components/OrderPop.vue";
import CancelPop from "@cps/MarketDetailComp/components/CancelPop.vue";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import $load from "@cps/GlobalLoading";
import CoinToken from "@/contracts/CoinToken.js";
// import { bpMul } from '@/utils/bpMath';

const $tx = useI18n();
const $route = useRoute();
const $router = useRouter();

// 要展示的类型: blindBox、calf、planet
const showCate = computed(() => {
  return $route.query.type;
});

// 初始化
const appStore = useAppStore();
const accStore = useAccountStore();
const cattleMintStore = useCattleMintStore();
const cattleStore = useCattleStore();
const blindBoxStore = useBlindBoxStore();
const planetStore = usePlanetStore();
cattleStore.createContract();
cattleMintStore.createContract();
planetStore.createContract();

/**
 * 构建代币对象
 */
let tokenObj;
if ($route.query.tradeType === "1") {
  tokenObj = new CoinToken(BVT_TOKEN_CONT);
} else if ($route.query.tradeType === "2") {
  tokenObj = new CoinToken(BVG_TOKEN_CONT);
} else if ($route.query.tradeType === "4") {
  tokenObj = new CoinToken(USDT_TOKEN_CONT);
}

/**
 * 获取牛牛详情
 */
const hasAllowance = ref(false);
const holder = $route.query.holder;
const isOwner = ref(false);
const id = $route.query.id;

watch(
  appStore.ethObj.chainId,
  async (old, news) => {
    // if (!appStore.rightLink) {
    //   await appStore.chainChanged($route.meta.needChains[0]);
    //   return;
    // }
    await init();
  },
  { immediate: true }
);

async function init() {
  try {
    // 卡牌归属者是否为自己
    if (appStore.defaultAccount == null) {
      isOwner.value = false;
    } else {
      isOwner.value =
        holder.toUpperCase() === appStore.defaultAccount?.toUpperCase();
      await isApproveToken();
    }

    if (showCate.value === "calf") {
      await setCattleDetailInfo($route.query.id);
    } else if (showCate.value === "planet") {
      await planetStore.getPlanetInfo($route.query.id, $route.query.cls);
      console.log("start..", planetStore.planetInfo);
    } else if (showCate.value === "blindBox") {
      const pids = await blindBoxStore.checkParents($route.query.id);
      setBlindBoxParents(pids);
      console.log("box pids..", pids);
    }
  } catch (error) {
    console.log("signer error...", error);
  }
}

async function isApproveToken() {
  // 判断是否授权
  if ($route.query.tradeType === "5") {
    hasAllowance.value = true;
  } else {
    // $route.query.price
    let decimals = await tokenObj.getDecimals();
    let amount = ethers.utils.parseUnits(String($route.query.price), decimals); //bpMul($route.query.price,Math.pow(10,decimals));
    hasAllowance.value = await tokenObj?.allow(
      appStore.defaultAccount,
      NFT_MINT_CONT.address,
      amount
    );
  }
}

async function setApproveToken() {
  $load({ isShow: true });
  // 判断是否授权
  if ($route.query.tradeType === "5") {
    hasAllowance.value = true;
  } else {
    const res = await tokenObj.auth(NFT_MINT_CONT.address);
    // console.log('setApproveToken',res)
    // if (res) {
    //   hasAllowance.value = true;
    // }
    isApproveToken();
    // hasAllowance.value = await tokenObj.allow(appStore.defaultAccount, NFT_MINT_CONT.address);
  }
  $load({ isShow: false });
}

// 控制订单弹窗
const showOrderPop = ref(false);
const showCancelPop = ref(false);
/**
 * 立即购买
 */
async function handleOpen() {
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  if (isOwner.value) {
    showCancelPop.value = true;
  } else {
    showOrderPop.value = true;
  }
}

/**
 * 订单确认
 */
async function orderConfirm() {
  // console.log('点击订单确认')
  if (hasAllowance.value === false) {
    try {
      await setApproveToken();
      ElMessage({
        message: $tx.t("msg.3"),
        type: "success"
      });
    } catch (error) {
      ElMessage({
        message: $tx.t("msg.4"),
        type: "error"
      });
    }
    $load({ isShow: false });
    return;
  }
  // 余额不足
  if (Number(accStore.banlance) < Number($route.query.price)) {
    ElMessage({
      message: $tx.t("msg.13"),
      type: "error"
    });
    return;
  }

  $load({ isShow: true });

  try {
    let decimals;
    if ($route.query.tradeType == 5) {
      decimals = 18;
    } else {
      decimals = await tokenObj.getDecimals();
    }

    const price = ethers.utils.parseUnits(String($route.query.price), decimals);
    const res = await cattleMintStore.buyCard({
      tradeType: $route.query.tradeType,
      goodsType: $route.query.type,
      amount: price.toString(),
      id: $route.query.id
    });
    ElMessage({
      message: $tx.t("msg.16"),
      type: "success"
    });
    showOrderPop.value = false;
    $router.push({ name: "nftMarket" });
  } catch (error) {
    console.log("buy..", error);
    let info = error?.data?.message || error?.message;
    // 点击了拒绝信息
    if (info?.includes?.("User denied transaction")) {
      info = "User denied transaction signature.";
    }
    // 移动端点击了拒绝信息
    if (error?.includes?.("cancel")) {
      info = "User denied transaction signature.";
    }
    // 移动端报错信息
    if (error?.reason) {
      info = error?.reason;
    }
    // 避免信息太长看懵用户
    info = String(info).length > 100 ? $tx.t("msg.39") : info;
    ElMessage({
      message: info,
      type: "error"
    });
  }
  $load({ isShow: false });
}

/**
 * 取消订单
 */
async function orderCancel() {
  // 链不对
  if (!appStore.rightLink) {
    await appStore.chainChanged($route.meta.needChains[0]);
    return;
  }
  $load({ isShow: true });
  const goodsType = { calf: 1, blindBox: 2, planet: 3 };
  try {
    await cattleMintStore.cancelSell({
      goodsType: goodsType[showCate.value],
      id: $route.query.id
    });
    ElMessage({
      message: $tx.t("msg.24"),
      type: "success"
    });
    showCancelPop.value = false;
    $router.push({ name: "nftMarket" });
  } catch (error) {
    ElMessage({
      message: $tx.t("msg.25"),
      type: "error"
    });
    console.log("order cancel..", error);
  }
  $load({ isShow: false });
}

// console.log('当前授权状态',hasAllowance.value)

// 分发牛牛属性
provide(
  "hasParents",
  computed(() => hasParents.value)
);

// 分发牛牛属性
provide(
  "cattleAttr",
  computed(() => cattleInfo.value)
);

// 分发公牛属性
provide(
  "fatherAttr",
  computed(() => fatherInfo.value)
);

// 分发母牛属性
provide(
  "motherAttr",
  computed(() => motherInfo.value)
);

// 分发小牛的死亡时间
provide(
  "cattleDeadTime",
  computed(() => cattleDeadTime.value)
);

// 分发星球属性
provide(
  "planetAttr",
  computed(() => planetStore.planetInfo)
);

// 分发地址
provide(
  "ownerAddr",
  computed(() => {
    return $route.query.holder;
  })
);

// 分发名称
provide(
  "cardName",
  computed(() => {
    if (showCate.value === "calf") {
      switch ($route.query.cls) {
        case "1":
          return `${$tx.t("card.3")} #${id}`;
          break;
        case "2":
          return `${$tx.t("card.4")} #${id}`;
          break;
        default:
          return "N/A";
          break;
      }
      // return cattleName.value;
    } else if (showCate.value === "planet") {
      return "home planet";
    } else if (showCate.value === "blindBox") {
      return "blind box";
    } else {
      return "N/A";
    }
  })
);

// 分发描述信息
provide(
  "desc",
  computed(() => {
    if (showCate.value === "calf") {
      return cattleDesc.value;
    } else if (showCate.value === "planet") {
      // 星球
      return $tx.t(planetStore.planetInfo.desc);
    } else if (showCate.value === "blindBox") {
      // 盲盒
      return $tx.t(blindBoxStore.blindBoxInfo?.desc);
    }
  })
);

// 分发图片
provide(
  "cardImg",
  computed(() => {
    if (showCate.value === "calf") {
      return cattleImg.value;
    } else if (showCate.value === "planet") {
      return planetStore.planetInfo.image;
      // return 'https://game.legendranch.app/legendaryranch/planet/homeland.png';
    } else if (showCate.value === "blindBox") {
      return "https://game.legendranch.app/legendaryranch/boxgif/box.png";
    } else {
      return "https://s3.bmp.ovh/imgs/2022/01/eac36c3b71fe903c.png";
    }
  })
);

// 分发 合约地址
provide(
  "contractAddr",
  computed(() => {
    if (showCate.value === "planet") {
      // 星球
      return STAR_CONT.address;
    } else if (showCate.value === "calf") {
      // 牛
      return CATTLE_CONT.address;
    } else if (showCate.value === "blindBox") {
      // 盲盒
      return POLYGON_BLIND_BOX_CONT.address;
    } else {
      return "N/A";
    }
  })
);

// 分发tokenId
provide("tokenId", $route.query.id);

// price
provide("priceObj", {
  price: $route.query.price,
  doller: $route.query.doller,
  tradeType: $route.query.tradeType
});
</script>

<template>
  <MarketDetail :showCate="showCate" @btn="showOrderPop = true">
    <template #btn>
      <button class="buy-btn" @click="handleOpen">{{ isOwner ? $t('detail.27') : $t('detail.14') }}</button>
    </template>
  </MarketDetail>
  <!-- 购买弹窗 -->
  <van-popup v-model:show="showOrderPop">
    <OrderPop :isApprove="hasAllowance" @confirm="orderConfirm" @cancel="showOrderPop = false" />
  </van-popup>
  <!-- 取消弹窗 -->
  <van-popup v-model:show="showCancelPop">
    <CancelPop :isApprove="true" @confirm="orderCancel" @cancel="showCancelPop = false" />
  </van-popup>
</template>

<style lang="scss" scoped></style>
