import { ethers, provider } from '@/utils/ethers';
import { storeToRefs, useAppStore } from '@/store/appStore.js';

/**
 * 获取账户余额
 * @returns {String} 余额
 */
export async function getAccountBalan() {
  const appStore = useAppStore();
  const balance = await provider.getBalance(appStore.defaultAccount);
  return ethers.utils.formatEther(balance);
}

/**
 * 判断是否授权 (ethers.js)
 * @param {Object} coin 被消耗的 币 合约对象
 * @param {String} contract 合约地址
 * @param {String} account 钱包地址（可选）
 * @returns {Boolean} true 已经授权，false 没有授权
 */
export async function allow(contract, coin, account) {
  const { defaultAccount } = storeToRefs(useAppStore());
  const addr = account || defaultAccount.value;
  const hasAllowance = await contract.allowance(addr, coin);
  return Number(hasAllowance) !== 0;
}

/**
 * 判断是否授权全部卡牌 (ethers.js)
 * @param {Object} coin 被消耗的 币 合约对象
 * @param {String} contract 合约地址
 * @param {String} account 钱包地址（可选）
 * @returns {Boolean} true 已经授权，false 没有授权
 */
export async function allowAll(coin, contract, account) {
  const { defaultAccount } = storeToRefs(useAppStore());
  const addr = account || defaultAccount.value;
  const approveAll = await coin.isApprovedForAll(addr, contract);
  return approveAll;
}

/**
 * 授权 (ethers.js)
 * @param {Object} coin 被消耗的 币 合约对象
 * @param {String} contract 合约地址
 */
export async function auth(coin, contract) {
  const approve = await coin.approve(contract, ethers.constants.MaxUint256);
  await approve?.wait?.();
}

/**
 * 授权全部卡牌 (ethers.js)
 * @param {Object} coin 被消耗的 币 合约对象
 * @param {String} contract 合约地址
 */
export async function authAll(coin, contract) {
  const approveAll = await coin.setApprovalForAll(contract, true);
  return await approveAll?.wait?.();
}
